import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

function AboutUsMainPage() {
  const bgImg =
    "https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721034480/leechiuweb3/Research%20and%20Property%20Valuation/finance-manager-meeting-discussing-company-growth-2023-11-27-04-57-13-utc_1_ycheiu.jpg";

  return (
    <motion.div
      className="bg-leechiuBlue "
      initial={{ x: -100 }}
      animate={{ x: 0 }}
      //   transition={{ duration: 0.5, type: "spring", stiffness: 100 }}
      ease="easeInOut"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | Leechiu Property Consultants </title>
        <meta
          name="description"
          content={
            "Leechiu Property Consultants (LPC) is a privately held professional real estate brokerage services company operating in the Philippines."
          }
        />
      </Helmet>
      <div
        className="h-[500px] w-full"
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-leechiuBlue h-full w-full bg-opacity-60 text-white flex items-center justify-center ">
          <div className="font-serif text-4xl font-semibold text-left w-full p-8 sm:p-20">
            About Us
          </div>
        </div>
      </div>
      <div className="h-12 w-full" />
      <div className="flex items-center justify-center flex-wrap ">
        <div className=" bg-red-50 h-[500px] w-full sm:w-1/2 ">
          <img
            src="https://res.cloudinary.com/dtnbpkyvd/image/upload/v1721027144/leechiuweb3/Investment%20Sales/cirnffkcolrbccxcccv7.jpg"
            className="w-full h-full object-cover"
            alt=""
          />
        </div>

        <div className="h-[500px] w-full sm:w-1/2 bg-white p-8 flex items-center justify-start  flex-col">
          <div className="flex items-center justify-center gap-16 text-leechiuBlue font-bold">
            <Link>Our Company</Link>
            <Link>Executive Leadership</Link>
            <Link>Our Brokers </Link>
            <Link>Careers</Link>
          </div>
          <div className="flex items-center justify-start mt-8 h-12 gap-2 w-full">
            <div className="w-2 h-full bg-leechiuOrange"></div>
            <div className="font-serif font-bold text-3xl text-leechiuBlue text-left ">
              Our Company
            </div>
          </div>
          <div className="text-justify mt-4 text-sm sm:text-lg ">
            {` Leechiu Property Consultants, Inc. (LPC) is a privately held
                professional real estate brokerage company founded by David Leechiu, the country’s top real estate adviser. LPC’s mission is to deliver profitable real estate solutions to its clients and partners. Since our establishment in 2016, we have leased more than one (1) million square meters of office space and               have sold thirty-two (32) billion pesos worth of real estate.`}
          </div>
          <div
            className="bg-leechiuOrange px-4 py-2 rounded-lg text-white text-lg self-start mt-8
          "
          >
            <Link to="/">Learn More</Link>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default AboutUsMainPage;
